import { Component, Input, OnInit, OnChanges, SimpleChanges, OnDestroy, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { Chart } from 'chart.js';

declare let $;

@Component({
  selector: 'app-chart-js-stacked-bar-chart',
  templateUrl: './chart-js-stacked-bar-chart.component.html',
  styleUrls: ['./chart-js-stacked-bar-chart.component.scss']
})
export class ChartJsStackedBarChartComponent implements OnInit, AfterViewInit,  OnChanges, OnDestroy {
  @Input() width: number = 1000;
  @Input() height: number = 250;
  @Input() ratio: number = 1;
  @Input() noLabels: boolean = false;
  @Input() noBorderGrid: boolean = false;
  isAfterViewInit: boolean = false;
  isReload: boolean = false;
  isDevice: boolean = false;
  id: number = Math.round(Math.random() * Math.random() * Math.random() * 1000);
  public chart: any;
  

  @Input() chartData: any = {
    labels: [''],
    datasets: [
      {
        label: '',
        data: [300, 50, 100],
        backgroundColor: [
          'rgb(255, 99, 132)',
          'rgb(54, 162, 235)',
          'rgb(255, 205, 86)'
        ],
        hoverOffset: 4,
        
      }
    ]
  };
  @Output() onComplete: EventEmitter<any> = new EventEmitter();
  constructor() {}
  ngOnInit() {}
  ngAfterViewInit(): void {
    this.isAfterViewInit = true;
    if (!this.chart) {
      this.createChart();
    }
  }
  ngOnChanges(simpleChanges: SimpleChanges) {
    const changes: any = simpleChanges;
    if (changes.chartData.currentValue && this.isAfterViewInit) {
      setTimeout(() => {
        this.createChart();
      }, 1000);
    }
  }
  ngOnDestroy(): void {
    if (this.chart) {
      this.chart.destroy();
    }
  }
  createChart() {
    if (this.chart) {
      this.chart.destroy();
    }
    this.chartData.labels = this.noLabels ? this.chartData.labels.map((item: any) => {return '';}) : this.chartData.labels;

    if (!$('#' + 'barChart' + this.id).length) {
      return;
    }

    this.chart = new Chart('barChart' + this.id, {
      type: 'bar',
      data: this.chartData,
      options: {
        animation: {
          onComplete: (chart) => {
            if(chart.initial){
              this.onComplete.emit(true);
            }
          }
        },
        aspectRatio: this.ratio,
        plugins: {
          legend: {
            display: false
          },
        },
        scales: {
          x: {
            stacked: true,
            grid: {
              color: 'rgba(0, 0, 0, 0.1)'
            },
          },
          y: {
            stacked: true,
            grid: {
              color: 'rgba(0, 0, 0, 0.1)'
            },
            
          }
        }
      }
    });
    this.chart.resize(this.width, this.height);
  }
}
