import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  Input,
  ViewEncapsulation,
} from "@angular/core";
import { Constant } from "../../constant";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { EventManagerService } from "../../service/event-manager.service";
import { debounceTime, Subject, Subscription } from "rxjs";
import { DateUtil } from "../../utils/date.util";
/**
 * declare Jquery
 * */
declare let $: any;

@Component({
  selector: "filter-start-end-date",
  templateUrl: "./filter-start-end-date.html",
  encapsulation: ViewEncapsulation.None,
  styleUrls: ["./filter-start-end-date.scss"],
})
export class FilterStartEndDateComponent  implements OnInit, OnDestroy {
  @Input("title") title: any;
  @Input("titleStart") titleStart: any;
  @Input("titleEnd") titleEnd: any;
  @Input("startDate") startDate: number;
  @Input("endDate") endDate: number;
  @Input("isDefaultData") isDefaultData: boolean = true;

  formDate: FormGroup = this.formBuilder.group({
    startDate: [null],
    endDate: [null],
  });
  Constant = Constant;
  format: string = 'dd/MM/yyyy';
  subscriptions: Subscription[];
  checkChange$ = new Subject();
  constructor(
    private formBuilder: FormBuilder,
    public eventManager: EventManagerService
  ) {
  }
  ngOnInit() {
    this.subscriptions = [
      this.checkChange$.pipe(
        debounceTime(500),
        ).subscribe((event)=> { return this.checkChange();}),
      ...[
        this.eventManager.subscribe("reset-data-filter", (res) => {
          this.setDefault();
        }),
        this.formDate.get("startDate").valueChanges.subscribe((val) => {
          const startDate = val ? 
          DateUtil.revertDate(val+ ', 00:00:00', this.format + ', hh:mm:ss') : null;
          this.startDate = startDate ? startDate.getTime() : null;
          this.checkChange$.next(true);
        }),
        this.formDate.get("endDate").valueChanges.subscribe((val) => {
          const endDate = val ? 
          DateUtil.revertDate(val+ ', 23:59:59', this.format + ', hh:mm:ss') : null;
          this.endDate = endDate ? endDate.getTime() : null;
          this.checkChange$.next(true);
        }),
      ],
    ];
    //  set default date
    this.setDefault();
  }
  ngOnDestroy() {
    this.eventManager.destroys(this.subscriptions);
  }
  checkChange(): void {
    if (this.startDate && this.endDate && this.startDate > this.endDate) {
      this.emitInputDate('startDate', DateUtil.parseDate(this.endDate, this.format));
      return;
    }
    this.eventManager.broadcast({
      name: "change-filter-start-end-date",
      content: this.emitData(),
    });
  }
  setNull() {
    this.startDate = null;
    this.setDate('startDate', null);
    this.endDate = null;
    this.setDate('endDate', null);
  }
  setDefault() {
    if (this.isDefaultData) {
      let date = new Date();
      this.endDate = date.valueOf();
      this.setDate('endDate', date);
      this.startDate = new Date(
        date.getMonth() + 1 + "/" + 1 + "/" + date.getFullYear()
      ).valueOf();
      this.setDate(
        'startDate',
        new Date(date.getMonth() + 1 + "/" + 1 + "/" + date.getFullYear())
      );
    } else {
      if (!this.startDate && !this.endDate) {
        this.setNull();
      } else {
        this.setDate('startDate', this.startDate);
        this.setDate('endDate', this.endDate);
      }
    }
  }
  setDate(field, date): void {
    // Set today date using the setValue function
    this.formDate.patchValue({
      [field]: date ? DateUtil.parseDate(date, this.format) : undefined,
    });
    this.checkValidDate(field);
  }
  emitInputDate(field, text) {
    this.formDate.patchValue({
      [field]: text,
    });
    this.checkValidDate(field);
  }
  checkValidDate(fieldInput) {
    if (this.startDate && this.endDate && this.startDate > this.endDate) {
      if (fieldInput === 'startDate') {
        this.setDate('endDate', this.startDate);
      } else {
        this.setDate('startDate', this.endDate);
      }
    }
  }
  emitData() {
    return {
      startDate: this.startDate,
      endDate: this.endDate,
    };
  }
  startDateNow() {
    document.getElementById("start-date").classList.toggle("active");
  }
  endDateNow() {
    document.getElementById("end-date").classList.toggle("active");
  }
}
