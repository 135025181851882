import { Pipe, PipeTransform } from '@angular/core';
@Pipe({name: 'money'})
export class TransformMoneyPipe implements PipeTransform {
  transform(value: number, unit?: string) {
      return this.parseMoney(value, unit);
  }
  parseMoney(number, unit?) {
    number = Math.round(number);
    if (number) {
      let numberString = number + '';
      let negative = false;
      if (numberString[0] === '-') {
         negative = true;
         numberString = numberString.substring(1, number.length);
      }
      const array = numberString.split('.');
      let integerNumber = (parseInt(array[0])) + ''; 
      let index = 0, str = '';
   
      // end
      for (let i= integerNumber.length - 1; i >= 0; i--) {
        index++;
        str= integerNumber.charAt(i) + str;
        if (index % 3 === 0) {
          str = ',' + str;
          index = 0;
        }
      }
      if (str.charAt(0) === ',') {
        str = str.substring(1, str.length);
      }
      negative && (str = '-' + str)
      return str + (unit || 'đ');
    } 
    else if (number === 0 || number === '0') {
      return '0' + (unit || 'đ');
    } 
    else {
      return '-/-';
    }
  }
}