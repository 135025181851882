<div class="d-flex flex-column">
  <div class="d-flex justify-content-start" *ngIf="unit">
    <div class="d-flex block">
      <label for="" class="block-tite"> {{title}}</label>
      <span class="block-unit">Đơn vị: <em>{{unit}} đồng</em></span>
    </div>
  </div>
  <div class="line-chart" style="position: relative" [style.width]="width" [style.height]="height + 'px'" *ngIf="width">
    <canvas id="lineChart{{id}}" [style.width]="width"></canvas>
  </div>
  <div class="d-flex justify-content-end">
    <span class="block-unit">(tuổi)</span>
  </div>
</div>