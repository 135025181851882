import { Pipe, PipeTransform } from '@angular/core';
@Pipe({name: 'checkNull'})
export class TransformNullPipe implements PipeTransform {
  transform(value: any, unit?) {
      return this.checkNull(value, unit);
  }
  checkNull(value, unit) {
    if (value !== null && value !== undefined) {
      return (value || '') + ' ' +  (unit || '');
    } else {
      return '-/-';
    }
  }
}