<div class="modal-content " [style.backgroundColor]="backgroundColor">
  <div class="modal-body">
    <div class="d-flex flex-column">
      <div class="d-flex align-items-center">
        <div class="icon" [style.backgroundColor]="color"></div>
        <span class="ml-2 title">{{title}}</span>
      </div>
      <div class="d-flex body-content" style="margin-top: 15px;">
        <div [innerHTML]="newValue"> </div>
      </div>
    </div>
  </div>
  <div class="modal-footer text-right">
    <a href="javascript: void(0)" (click)="dismiss()">Đóng</a>
  </div>
</div>