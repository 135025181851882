import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { Subject, debounceTime } from "rxjs";
/**
 * declare Jquery
 * */
declare let $: any;

@Component({
  selector: "input-note",
  styleUrls: ["./input-note.component.scss"],
  templateUrl: "./input-note.component.html",
})
export class InputNoteComponent implements OnInit, OnChanges
{
  @Input() value: any;
  @Output() valueChange: EventEmitter<any> = new EventEmitter();
  @Input() maxLength: any;
  @Input() rows: any;
  @Input() cols: any;
  @Input() placeholder: any;
  @Input() readonly: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() isValidated: boolean = false;
  @Input() isError: boolean = false;
  @Input() background: string = '#FFFADE';
  @Output() result: EventEmitter<any> = new EventEmitter();
  @ViewChild("autoResizeTextarea") textarea: ElementRef;
  viewValue: string;
  minHeight: string = '100px';
  callApi$ = new Subject();
  callHeight$ = new Subject();
  beforeKeydown: any;
  constructor(
  ) {
  }

  ngOnInit() {
    this.callApi$.pipe(
      debounceTime(100),
      ).subscribe((event)=> { return this.emitData();});
    this.callHeight$.pipe(
        debounceTime(1000),
        ).subscribe((event)=> { return this.autoResize();});
    this.value = this.value || '';
    if (this.rows) {
      this.minHeight = (this.rows * 38) + 'px';
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.value && changes.value.currentValue && this.readonly) {
      this.viewValue = changes.value.currentValue.replaceAll('\n', '<br>');
    }
    this.callHeight$.next(true);
  }
  updateForm(): void {
  }
  emitData() {
    if ( (this.value || '').length > this.maxLength ) {
      setTimeout(() => {
        this.value = (this.value || '').substring(0, this.maxLength);
        this.valueChange.emit(this.value);
      }, 10)
    }
    this.valueChange.emit(this.value);
  }
  changeValue() {
    this.callApi$.next(true);
  }
  onInput() {
    this.callHeight$.next(true);
  }
  autoResize() {
    const textareaElement = this.textarea.nativeElement;
    textareaElement.style.height = `${textareaElement.scrollHeight}px`;
  }
  catchKeydown(event) {
    const key = event.key;

    if (key === 'Backspace' || key === 'ArrowLeft' || key === 'ArrowRight' || key === 'Tab' || key === 'Delete') {
      return;
   }

   if (key === 'Control') {
       this.beforeKeydown = key;
   }

   if ((key === 'v' && this.beforeKeydown === 'Control'  )) {
       this.beforeKeydown = '';
       return;
   }

   if ((key === 'c' && this.beforeKeydown === 'Control'  )) {
       this.beforeKeydown = '';
       return;
   }
   if ((key === 'a' && this.beforeKeydown === 'Control'  )) {
    this.beforeKeydown = '';
    return;
  }

  if ( (this.value || '').length === this.maxLength ) {
      event.preventDefault();
      return;
    }
  }
}
